@use 'wdx';
@import 'scss/direct-import';
@import 'app-scss/vendors';
@import '../../../node_modules/bootstrap/scss/tooltip';

/**
* Contains global shared styles for portal including some minor overrdie tweaks
*/

@import 'scss/highcharts';

:root {
    --#{$variable-prefix}header-bg: --#{$variable-prefix}body-bg;
    --#{$variable-prefix}header-color: --#{$variable-prefix}body-color;
}

// Tables
.table {
    th {
        border-top: 0;
    }
    td,
    th {
        padding: 1em 0.6em;
    }

    > :not(:first-child) {
        border-top-width: 1px;
    }
}

// Grid
.grid {
    gap: 1.5em;
}

// Utils
.content-container {
    max-width: 100rem;
    margin: 0 auto;
}

.stronger {
    font-weight: 500;
}

.richtext--card {
    p,
    li {
        margin-bottom: 0.5em;
    }
}

.richtext--article {
    p,
    li {
        line-height: 2;
        margin-bottom: 1em;
    }

    h1 {
        font-size: 2rem;
        margin-bottom: 0.7em;
        font-weight: 500;
    }

    h3 {
        font-size: 1.6rem;
        margin-bottom: 0.7em;
        font-weight: 500;
    }
}

.richtext-carousel {
    ul {
        padding-left: 0 !important;
    }
}

// Carousel

.carousel-control-next,
.carousel-control-prev {
    width: 4%;
    margin-top: -30px;

    @include wdx.media-breakpoint-up('lg') {
        width: 4%;
    }
}

.carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

// bootstrap overrides

.tooltip {
    &.show {
        opacity: 1;
    }
    .tooltip-inner {
        max-width: $tooltip-max-width;
        padding: 0.5rem 1rem;
        background-color: wdx.$body-bg;
        color: wdx.$body-color;
        box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
        border-radius: 2px;
    }

    .tooltip-arrow::before {
        border-top-color: wdx.$body-bg;
    }
}

.badge {
    padding: 0.75rem;
}

// forms

.form-control {
    padding: 1rem 1.2rem;
}
wdx-ff-form-container {
    input[type='text'] {
        min-height: 4.4rem !important;
    }

    .ng-select-container {
        min-height: 4.4rem !important;

        input {
            margin-top: -0.5rem;
        }

        .ng-clear-wrapper {
            padding-top: 0.3rem;
        }
    }
}

// misc 3rd party

ngb-modal-window {
    .modal-content {
        border-radius: 0;
    }
}

ngx-dropzone {
    font-size: 1.6rem !important;
}

.feature-svg-container {
    --clmi-svg-primary: #{wdx.$primary-light};
    --clmi-svg-primary-fill: #{wdx.$primary-light};
    --clmi-svg-secondary: #{wdx.$secondary-light};
    --clmi-svg-secondary-fill: #{wdx.$secondary-light};
    --clmi-svg-bg: #{wdx.$body-bg-muted};
}
